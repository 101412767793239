import React, { Component } from "react"
import Layout from "../../components/layout"
import HeaderBanner from "../../components/common/headerBanner/headerBanner"
import CTABanner from "../../components/common/ctaBanner/ctaBanner"
import * as styles from "../faq.module.scss"
import Breadcrumb from "../../components/common/breadcrumb/breadcrumb"
import FAQCategoriesContainer from "../../components/sections/faq/faqCategoriesContainer/faqCategoriesContainer"
import FAQListContainer from "../../components/sections/faq/faqListContainer/faqListContainer"
import { TM } from "../../components/common/typography/trademark"

const generalQuestions = [
  {
    label: "What is ICC?",
    id: "whatIs",
    widget: "text",
    option: (
      <>
        ICC stands for IronCAP Crypto, an NIST-approved quantum-safe
        cryptographic system utilizing NIST-approved post-quantum cryptography
        (PQC) algorithms and IronCAP
        <TM />
        's ground breaking technologies. IronCAP
        <TM /> Toolkits provide the application programming interface to
        developers to let them easily integrate ICC with their applications.
        This transforms their applications to be safe against cyberattacks from
        not only computers today but also against those from upcoming quantum
        computers.
      </>
    ),
  },
  {
    label: "What is the cost of using IronCAP Toolkits?",
    id: "cost",
    widget: "text",
    option:
      "ICC Toolkits is designed to be extremely cost effective to fit all possible vertical industries. Please contact us to discuss your needs. We will tailor a deal to best fit your specific requirements and business nature.",
  },
  {
    label: "How do I license IronCAP Toolkits?",
    id: "license",
    widget: "text",
    option:
      "Please contact us by email at sales@ironcap.ca or give us a call at 1-800-668-2185 for licensing information.",
  },
]

const howToUse = [
  {
    label: "What kind of cryptographic features does IronCAP Toolkits provide?",
    id: "features",
    widget: "text",
    option: `IronCAP Toolkits provides 5 functionalities:\r
    1) Private/Public key generation
    2) Encryption
    3) Decryption
    4) Digital signature
    5) Signature verification`,
  },
  {
    label: "How do I use IronCAP Toolkits in my application?",
    id: "howDoIUse",
    widget: "text",
    option:
      "IronCAP Toolkits provides an application programming interface (API) that is compatible with industry standards such as PKCS#11, OpenSSL, and OpenPGP. It is implemented as a shared / DLL library and comes with C header files which include definitions for ICC-specific object types (keys) as well as all the standard PKCS#11 definitions. This assures portability and rapid integration into new, or already existing applications.",
  },
]

const categories = [
  { label: "General Questions", id: "generalQuestions" },
  { label: "How to use IronCAP Toolkits", id: "howToUse" },
]

const fileCollection = {
  generalQuestions,
  howToUse,
}

class IronCAPAPIFAQ extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeCategory: "generalQuestions",
    }
  }

  setActiveCategory = e => {
    this.setState({
      activeCategory: e.target.id,
    })
  }
  render() {
    return (
      <Layout>
        <HeaderBanner
          title={
            <>
              IronCAP
              <TM /> Toolkits FAQs
            </>
          }
        />
        <div className={styles.pageContainer}>
          <Breadcrumb
            firstLink="/support"
            firstLinkTitle="Support"
            secondLink="/ironcap-toolkits/faq"
            secondLinkTitle={
              <>
                IronCAP
                <TM /> Toolkits FAQs
              </>
            }
          />
          <div className={styles.FAQContainer}>
            <FAQCategoriesContainer
              categories={categories}
              setActiveCategory={this.setActiveCategory}
              activeCategory={this.state.activeCategory}
            />
            <FAQListContainer
              activeCategory={fileCollection[this.state.activeCategory]}
            />
          </div>
        </div>
        <CTABanner
          tagline="Step Into The Future"
          CTA="video"
          CTADetail="how video"
        />
      </Layout>
    )
  }
}

export default IronCAPAPIFAQ
